import { DiscountCampaign } from './types';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import classnames from 'classnames';

interface Props {
    campaign: DiscountCampaign;
    edit: () => void;
    editEnabled: boolean;
}

export function CampaignRow({ campaign, edit, editEnabled }: Props) {
    return (
        <tr>
            <td>{campaign.name}</td>
            <td>
                <Checkbox checked={Boolean(campaign.costAbsorption)} className={'pointer-events-none'} />
            </td>
            <td>{campaign.startsAt ?? 'not set'}</td>
            <td>{campaign.endsAt ?? 'not set'}</td>
            <td>
                <button
                    className={classnames('btn btn-action btn-icon-only text-size-12', !editEnabled && 'disabled')}
                    onClick={edit}
                    aria-label='edit'
                >
                    <span className='rioglyph rioglyph-pencil' aria-hidden='true' />
                </button>
            </td>
        </tr>
    );
}
