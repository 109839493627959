import { useState } from 'react';
import { ResourceActivation, BrowserInput } from './types';
import { DisplayValidationError } from './DisplayValidationErrors';
import { deactivateResources } from './deactivationService';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';

export const DeactivationSection = (props: { input: BrowserInput }) => {
    const { input } = props;
    const [deactivationResult, setDeactivationResult] = useState<Array<ResourceActivation> | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeactivateAllResources, setDeactivateAllResources] = useState(false);

    const hasProductWithLevels = input.items.some((it) => it.level !== undefined);

    return (
        <div>
            <button
                className={'btn btn-primary margin-20'}
                onClick={() =>
                    deactivateResources(input, setDeactivationResult, setIsLoading, isDeactivateAllResources)
                }
            >
                Deactivate resources
            </button>
            <Checkbox
                className={'margin-left-20'}
                onClick={(event: React.FormEvent<HTMLInputElement>) =>
                    setDeactivateAllResources(event.currentTarget.checked)
                }
            >
                Deactivate all resources for the given accounts and products.
                {hasProductWithLevels ? ' Warning: Deactivates all levels!' : ''}
            </Checkbox>
            <DisplayValidationError
                validationResult={deactivationResult}
                isLoading={isLoading}
                expanderText={'Validation result for deactivation'}
                loadingText={'Deactivating the resources...'}
                startText={'No deactivation happened yet'}
                noErrorText={'All Deactivations happened without an error'}
            />
        </div>
    );
};
