import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { ResourceActivation } from './types';

interface Props {
    validationResult: Array<ResourceActivation> | undefined;
    isLoading: boolean;
    loadingText: string;
    noItemsText: string;
    expanderText: string;
}

export const DisplayActivationStates = (props: Props) => {
    const { validationResult, isLoading, loadingText, noItemsText, expanderText } = props;

    if (isLoading) {
        return <Spinner text={loadingText} />;
    }

    if (validationResult === undefined) {
        return <div>{'Not yet executed'}</div>;
    }

    return (
        <>
            {validationResult.length === 0 ? (
                <div>{noItemsText}</div>
            ) : (
                <ExpanderPanel title={<b>{expanderText}</b>} bsStyle='default' open={true}>
                    <>
                        <table className={'table'}>
                            <thead>
                                <tr>
                                    {['AccountId', 'ProductId', 'Level', 'ResourceId', 'State'].map((it) => (
                                        <th key={it}>{it}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {validationResult.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.accountId}</td>
                                        <td>{item.productId}</td>
                                        <td>{item.level ?? '---'}</td>
                                        <td>{item.resourceId ?? ''}</td>
                                        <td>{item.productState}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                </ExpanderPanel>
            )}
        </>
    );
};
