import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { ResourceActivation, ProductState } from './types';
import { ProductStateMultiSelect } from './ValidationSection';
import { useState } from 'react';

interface Props {
    validationResult: Array<ResourceActivation> | undefined;
    isLoading: boolean;
    loadingText: string;
    startText: string;
    noErrorText: string;
    expanderText: string;
    stateInsteadOfError?: boolean;
}

export const DisplayValidationError = (props: Props) => {
    const { validationResult, isLoading, loadingText, startText, noErrorText, expanderText, stateInsteadOfError } =
        props;
    const [activationStates, setActivationStates] = useState<Array<ProductState>>([]);

    if (isLoading) {
        return <Spinner text={loadingText} />;
    }

    if (validationResult === undefined) {
        return <div>{startText}</div>;
    }

    return (
        <>
            {validationResult.length === 0 ? (
                <div className={'panel panel-default padding-10'}>
                    <b>{noErrorText}</b>
                </div>
            ) : (
                <ExpanderPanel title={<b>{expanderText}</b>} bsStyle='default' open={true}>
                    <>
                        <ProductStateMultiSelect
                            desiredProductStates={activationStates}
                            setDesiredProductStates={setActivationStates}
                        />
                        <table className={'table'}>
                            <thead>
                                <tr>
                                    {[
                                        'AccountId',
                                        'ProductId',
                                        'Level',
                                        'ResourceId',
                                        stateInsteadOfError ? 'State' : 'Error',
                                    ].map((it) => (
                                        <th key={it}>{it}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {validationResult
                                    .filter((asset) =>
                                        activationStates.length === 0
                                            ? true
                                            : activationStates.includes(asset.productState ?? ProductState.INACTIVE)
                                    )
                                    .map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.accountId}</td>
                                            <td>{item.productId}</td>
                                            <td>{item.level ?? '---'}</td>
                                            <td>{item.resourceId ?? ''}</td>
                                            <td>{item.error}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </>
                </ExpanderPanel>
            )}
        </>
    );
};
