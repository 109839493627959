import { ActivationSummary } from '../api/activation/activation.types';
import { products } from './ProductSelect';

export const AccountSummary = (props: { summary: Array<ActivationSummary> }) => {
    const { summary } = props;

    const enhancedSummary = summary
        .map((it) => ({
            ...it,
            productName: products.find((product) => product.id.toLowerCase() === it.productId.toLowerCase())?.name,
        }))
        .sort((a, b) => (a.productName ?? a.productId).localeCompare(b.productName ?? a.productId));

    return (
        <table className={'table'}>
            <thead>
                <tr>
                    {['Name', 'ProductId', 'Booked resources (Active or pending activation)'].map((it) => (
                        <th key={it}>{it}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {enhancedSummary.map((item, index) => (
                    <tr key={index}>
                        <td>{item.productName ?? ''}</td>
                        <td>{item.productId}</td>
                        <td>{item.amount}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
