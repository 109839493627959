import ClearableInput from '@rio-cloud/rio-uikit/lib/es/ClearableInput';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import { ProductSelect } from '../ProductSelect';
import { useState } from 'react';
import { BookableInfo, InputData } from '../types';
import { ConditionallyEnabledButton } from '../ConditionallyEnabledButton';
import { BookableInfoDialog } from './BookableInfoDialog';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { fetchBookableInfos } from '../../api/activation/bookableCall';

export function BookableResource() {
    const [showDialog, setShowDialog] = useState(false);
    const [inputData, setInputData] = useState<InputData>({
        accountId: '',
        productId: '',
        resourceId: '',
        level: '',
    });
    const [bookableInfoResult, setBookableInfoResult] = useState<Array<BookableInfo>>();

    const missingData = inputData.productId === '' || inputData.accountId === '';
    const [inProgress, setInProgress] = useState<boolean>(false);

    return (
        <div className={'margin-20'}>
            <ExpanderPanel title={<h5>Bookable Resources</h5>} bsStyle={'default'}>
                <div>
                    <div className={'form-group'}>
                        <label>{'Account id*'}</label>
                        <ClearableInput
                            placeholder='Insert account id'
                            value={inputData.accountId}
                            onChange={(accountId: string) =>
                                setInputData({ ...inputData, accountId: accountId.trim() })
                            }
                        />
                    </div>
                    <ProductSelect
                        onChange={(item) => setInputData({ ...inputData, productId: item?.id ?? '' })}
                        selectedId={inputData.productId}
                        purpose='view-activation'
                    />
                    <div className={'form-group'}>
                        <label>{'Level'}</label>
                        <ClearableInput
                            placeholder='Insert level'
                            value={inputData.level}
                            onChange={(level: string) => setInputData({ ...inputData, level: level.trim() })}
                        />
                    </div>
                    <ConditionallyEnabledButton
                        disabledText={getDisabledText(missingData, inProgress)}
                        onClick={async () => {
                            try {
                                setInProgress(true);
                                let isSuccess = true;
                                await fetchBookableInfos(inputData.accountId, inputData.productId, inputData.level)
                                    .then(setBookableInfoResult)
                                    .catch((e) => {
                                        isSuccess = false;
                                        Notification.error(`Could not call bookable service: ${e.message}`);
                                    })
                                    .finally(() => {
                                        setInProgress(false);
                                        if (isSuccess) {
                                            setShowDialog(true);
                                        }
                                    });
                            } catch (e) {
                                setInProgress(false);
                                Notification.error(`Could not call bookable service: ${e.message}`);
                            }
                        }}
                    >
                        Retrieve bookable resources
                    </ConditionallyEnabledButton>
                    {showDialog && (
                        <BookableInfoDialog
                            bookableResourceResult={bookableInfoResult}
                            onClickClose={() => {
                                setShowDialog(false);
                                setInProgress(false);
                            }}
                        />
                    )}
                </div>
            </ExpanderPanel>
        </div>
    );
}

function getDisabledText(missingData: boolean, inProgress: boolean) {
    if (missingData) {
        return 'Please enter account id and service sku';
    }
    if (inProgress) {
        return 'In progress ...';
    }
    return undefined;
}
