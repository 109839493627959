import { userProfileObtained, userSessionExpired, userSessionRenewed } from './login/loginSlice';
import { configureMockUserManager, configureUserManager, createUserManager, SessionRenewedResult } from './login/login';
import { store } from './setup/store';
import { accessToken } from './tokenHandling/accessToken';
import { accessTokenStored, idTokenStored } from './tokenHandling/tokenSlice';
import { trace } from './setup/trace';
import { attemptInitialSignIn } from './setup/oauth';
import { config } from '../config';
import { reportErrorToSentry } from './setup/sentry';
import { UserManager } from 'oidc-client';

export interface OAuthConfig {
    onSessionExpired: Function;
    onSessionRenewed: Function;
}

export const main = async (renderApp: Function) => {
    const oauthConfig = {
        onSessionExpired: () => {
            accessToken.discardAccessToken();
            store.dispatch(userSessionExpired());
        },
        onSessionRenewed: (result: SessionRenewedResult) => {
            trace('index.onTokenRenewed', result);

            accessToken.saveAccessToken(result.accessToken);
            store.dispatch(accessTokenStored(result.accessToken));
            store.dispatch(idTokenStored(result.idToken));
            store.dispatch(userProfileObtained(result.profile));

            store.dispatch(userSessionRenewed());
        },
    } as OAuthConfig;

    const isAllowedToMockAuth = process.env.NODE_ENV !== 'production';
    const userManager: UserManager =
        isAllowedToMockAuth && config.login.mockAuthorization
            ? (configureMockUserManager(oauthConfig) as UserManager)
            : configureUserManager(oauthConfig, createUserManager());

    try {
        await userManager.clearStaleState();
        await attemptInitialSignIn(userManager);
        renderApp();
    } catch (error) {
        trace('could not start application', error);
        reportErrorToSentry(error);
    }
};
