import { BrowserInput } from './types';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import { FALLBACK_BROWSER_INPUT } from './inputReader';

const START_TEXT = 'Please add a variable "input" to "window" in the browser console';
const ERROR_HEADER = 'Something went wrong!';
const INPUT_EXPANDER_HEADER = 'Show imported data';

export const DisplayInput = (props: { input: BrowserInput }) => {
    const { input } = props;

    if (input.error === FALLBACK_BROWSER_INPUT.error) {
        return (
            <div className={'margin-20'}>
                <b>{START_TEXT}</b>
            </div>
        );
    }

    if (input.error) {
        return (
            <div className={'margin-20'}>
                <b>{ERROR_HEADER}</b>
                {input.error}
            </div>
        );
    }

    return (
        <div className={'margin-20'}>
            <ExpanderPanel title={INPUT_EXPANDER_HEADER} bsStyle='default' open={true}>
                <table className={'table'}>
                    <thead>
                        <tr>
                            {['AccountId', 'ProductId', 'Level', 'ResourceIds'].map((it) => (
                                <th key={it}>{it}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {input.items.map((item, index) => (
                            <tr key={index}>
                                <td>{item.accountId}</td>
                                <td>{item.productId}</td>
                                <td>{item.level ?? '---'}</td>
                                <td style={{ whiteSpace: 'pre-wrap' }}>{item.resourceIds.join(',\n')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </ExpanderPanel>
        </div>
    );
};
