import { getMarketplaceBackend } from '../../config/appConfig';
import { getRequest } from '../requests';
import { decodeJson, jsonOrReject } from '../api';
import { ApiResourceActivationsCodec } from './activation.types';
import { mapResourceActivationsResponse } from './resourceMapper';
import { getParameters } from '../parameterService';
import { OverviewResource } from '../../activation/types';

export function fetchActivationsForProduct(accountId: string, productId: string): Promise<Array<OverviewResource>> {
    // eslint-disable-next-line camelcase
    const params = getParameters({ product_id: productId });
    return fetch(`${getMarketplaceBackend()}/admin/${accountId}/activations${params}`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiResourceActivationsCodec))
        .then(mapResourceActivationsResponse);
}
