import ClearableInput from '@rio-cloud/rio-uikit/lib/es/ClearableInput';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import { useState } from 'react';
import { ConditionallyEnabledButton } from '../ConditionallyEnabledButton';
import { validateStates } from '../stateValidator';
import { InputData, ProductState, ResourceActivation } from '../types';
import { deactivateResourcesForProduct } from '../../api/activation/deactivationCall';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { splitResourceIds } from '../resourceIdSplitter';
import { ProductSelect } from '../ProductSelect';
import { activateResources } from '../activationService';
import { mapResourceActivations } from '../inputReader';
import { ResetResultSection } from './ResetResultSection';
import { ResetDialog } from './ResetDialog';

let persistedResult: Array<ResourceActivation> | undefined;

const activeStates = [ProductState.PENDING_ACTIVATION, ProductState.ACTIVE];

function getDisabledText(missingData: boolean, isResetting: boolean) {
    if (missingData) {
        return 'Please enter account id, service sku and resource ids';
    }
    if (isResetting) {
        return 'Reset running ...';
    }
    return undefined;
}

export const ResetActivationSingleProduct = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [inputData, setInputData] = useState<InputData>({
        accountId: '',
        productId: '',
        resourceId: '',
    });

    const [isResetting, setIsResetting] = useState<boolean>(false);
    const missingData =
        inputData.productId === '' || inputData.accountId === '' || splitResourceIds(inputData.resourceId).length === 0;

    const [activationResult, setActivationResult] = useState<Array<ResourceActivation> | undefined>();
    const [beforeDeactivation, setBeforeDeactivation] = useState<Array<ResourceActivation> | undefined>(undefined);
    const [afterActivation, setAfterActivation] = useState<Array<ResourceActivation> | undefined>(undefined);
    const [afterDeactivation, setAfterDeactivation] = useState<Array<ResourceActivation> | undefined>(undefined);

    const activationItems = [
        {
            accountId: inputData.accountId,
            productId: inputData.productId,
            resourceIds: splitResourceIds(inputData.resourceId),
        },
    ];

    return (
        <div className={'margin-20'}>
            <ExpanderPanel title={<h5>Reset activation for a single service</h5>} bsStyle={'default'}>
                <div>
                    <div className={'form-group'}>
                        <label>{'Account id*'}</label>
                        <ClearableInput
                            placeholder='Insert account id'
                            value={inputData.accountId}
                            onChange={(accountId: string) =>
                                setInputData({ ...inputData, accountId: accountId.trim() })
                            }
                        />
                    </div>
                    <ProductSelect
                        onChange={(item) => setInputData({ ...inputData, productId: item?.id ?? '' })}
                        selectedId={inputData.productId}
                        purpose='reset'
                    />
                    <div className={'form-group'}>
                        <label>{'Resource ids (comma-separated)*'}</label>
                        <ClearableInput
                            placeholder='Insert resource ids'
                            value={inputData.resourceId}
                            onChange={(resourceId: string) =>
                                setInputData({ ...inputData, resourceId: resourceId.trim() })
                            }
                        />
                    </div>
                    <ResetResultSection
                        afterDeactivation={afterDeactivation}
                        beforeDeactivation={beforeDeactivation}
                        activationResult={activationResult}
                        afterActivation={afterActivation}
                        resetting={isResetting}
                    />
                </div>
                <ConditionallyEnabledButton
                    disabledText={getDisabledText(missingData, isResetting)}
                    onClick={async () => {
                        setShowDialog(true);
                        setBeforeDeactivation(undefined);
                        setAfterDeactivation(undefined);
                        setActivationResult(undefined);
                        setAfterActivation(undefined);
                    }}
                >
                    Reset activations
                </ConditionallyEnabledButton>
                {showDialog && (
                    <ResetDialog
                        activationItems={activationItems}
                        resetting={isResetting}
                        onClickCancel={() => setShowDialog(false)}
                        onClickConfirm={async () => {
                            setIsResetting(true);
                            try {
                                await validateStates(
                                    activationItems,
                                    (result) => {
                                        persistedResult = result;
                                        setBeforeDeactivation(result);
                                    },
                                    activeStates
                                );
                                await deactivateResourcesForProduct(
                                    inputData.accountId,
                                    inputData.productId,
                                    splitResourceIds(inputData.resourceId)
                                );
                                setShowDialog(false);

                                await validateStates(activationItems, setAfterDeactivation, [ProductState.INACTIVE]);
                                await activateResources(
                                    mapResourceActivations(persistedResult),
                                    setActivationResult,
                                    () => 0
                                );
                                setTimeout(async () => {
                                    await validateStates(activationItems, setAfterActivation, activeStates);
                                    setIsResetting(false);
                                    Notification.success(`Successfully reset service ${inputData.productId}`);
                                }, 3000);
                            } catch (e) {
                                Notification.error(`Could not deactivate service: ${e.message}`);
                                setIsResetting(false);
                            }
                        }}
                    />
                )}
            </ExpanderPanel>
        </div>
    );
};
