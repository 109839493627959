import './polyfills';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { main } from './configuration';
import { config } from './config';
import { AppContainer } from './features/app/AppContainer';
import { NoMatch } from './features/app/NoMatch';
import { ErrorBoundary } from './features/app/ErrorBoundary';
import { store } from './configuration/setup/store';
import handleLoginRedirect from './configuration/login/redirect';
import { ERROR_ROUTE } from './features/app/routes/routes';

const renderApplication = () => {
    // Note that we need to use the base "Router" with a "hash" history
    // because the "HashRouter" doesn't allow handing in a history
    // from the outside. So this is effectively a "HashRouter" despite
    // that not being obvious here
    createRoot(document.getElementById('root') as HTMLElement).render(
        <ErrorBoundary>
            <Provider store={store}>
                <BrowserRouter>
                    <Routes>
                        <Route path={`/${ERROR_ROUTE}`} element={<NoMatch />} />
                        <Route path={'/*'} element={<AppContainer />} />
                    </Routes>
                </BrowserRouter>
            </Provider>
        </ErrorBoundary>
    );
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else {
    main(renderApplication);
}
