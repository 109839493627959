import { getMarketplaceBackend } from '../../config/appConfig';
import { getRequest } from '../requests';
import { decodeJson, jsonOrReject } from '../api';
import { ApiBookableResources, ApiBookableResourcesCodec } from './activation.types';
import { BookableInfo } from '../../activation/types';
import { convertApiActivationState } from './convertApiActivationState';

export function fetchBookableInfos(accountId: string, productId: string, level?: string): Promise<Array<BookableInfo>> {
    const levelParam = level ? `?level=${level}` : '';
    return fetch(
        `${getMarketplaceBackend()}/admin/${accountId}/activations/${productId}/bookable${levelParam}`,
        getRequest()
    )
        .then(jsonOrReject)
        .then(decodeJson(ApiBookableResourcesCodec))
        .then(mapActivationBookableResponse);
}

function mapActivationBookableResponse(apiBookableResources: ApiBookableResources): Array<BookableInfo> {
    return apiBookableResources.resources.map((resource) => ({
        resourceId: resource.resource_id,
        bookable: resource.bookable,
        activationState: convertApiActivationState(resource.activation_state),
        resourceType: resource.resource_type,
        resourceName: resource.resource_name,
        originLevel: resource.origin_level,
        targetLevel: resource.target_level,
        missingRequirements: resource.missing_requirements?.map((requirement) =>
            requirement.map((missingFeature) => ({
                featureName: missingFeature.feature_name,
                actual: missingFeature.actual,
                expectedValues: missingFeature.expected_values,
                inclusive: missingFeature.inclusive,
            }))
        ),
        hasOpenContract: resource.has_open_contract,
    }));
}
