import { useState } from 'react';
import { downloadDiscountExcel } from '../api/discount/discountCalls';
import { DiscountType } from '../api/discount/discount.types';

export function DiscountDownload(props: { discountType: DiscountType }) {
    const [isDownloading, setIsDownloading] = useState(false);

    return (
        <button
            key={isDownloading ? 'down' : 'ready'}
            className={`btn btn-default ${isDownloading ? 'btn-loading' : ''}`}
            disabled={isDownloading}
            onClick={() => downloadDiscountExcel(setIsDownloading, props.discountType)}
        >
            {!isDownloading && <span className='rioglyph rioglyph-cloud-download' aria-hidden='true' />}
            Export all discounts
        </button>
    );
}
