import React from 'react';

interface Props {
    disabledText: string | undefined;
    onClick: Function;
    children: React.ReactNode;
}

export const ConditionallyEnabledButton = ({ disabledText, onClick, children }: Props) => (
    <>
        {disabledText && (
            <div className='text-color-warning'>
                <span className='rioglyph rioglyph-warning-sign margin-right-5' />
                <b>{disabledText}</b>
            </div>
        )}
        <button className='btn btn-primary pull-right' disabled={Boolean(disabledText)} onClick={() => onClick()}>
            {children}
        </button>
    </>
);
