/* eslint-disable camelcase */
import { ApiAdminDiscountCampaign, ApiAdminDiscountCampaigns } from './discount.types';
import { DiscountCampaign } from '../../discount/types';

export const mapApiCampaign = (campaign: ApiAdminDiscountCampaign): DiscountCampaign => ({
    id: campaign.id,
    name: campaign.name,
    costAbsorption: campaign.cost_absorption,
    startsAt: campaign.starts_at,
    endsAt: campaign.ends_at,
});

export function mapApiCampaigns(apiCampaigns: ApiAdminDiscountCampaigns): Array<DiscountCampaign> {
    return apiCampaigns.campaigns.map(mapApiCampaign);
}

export function mapCampaignToApi(campaigns: DiscountCampaign): ApiAdminDiscountCampaign {
    return {
        id: campaigns.id,
        name: campaigns.name,
        cost_absorption: campaigns.costAbsorption,
        starts_at: campaigns.startsAt,
        ends_at: campaigns.endsAt,
    };
}
