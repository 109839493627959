import ClearableInput from '@rio-cloud/rio-uikit/lib/es/ClearableInput';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';
import {useState} from 'react';
import {ConditionallyEnabledButton} from './ConditionallyEnabledButton';
import {fetchActivationsSummary} from '../api/activation/activationSummaryCall';
import {ActivationSummary} from '../api/activation/activation.types';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import {nonDefaultActivatedProducts} from './ProductSelect';
import {deactivateResourcesForProduct} from '../api/activation/deactivationCall';
import {AccountSummary} from './AccountSummary';
import Checkbox from "@rio-cloud/rio-uikit/lib/es/Checkbox";

export const ForcedCancellationFullAccount = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [accountId, setAccountId] = useState<string>('');
    const [includeServiceCare, setIncludeServiceCare] = useState<boolean>(false);
    const missingData = accountId === '';
    const [isDeactivating, setIsDeactivating] = useState<boolean>(false);
    const [summary, setSummary] = useState<Array<ActivationSummary> | undefined>();
    return (
        <div className={'margin-20'}>
            <ExpanderPanel title={<h5>Forced deactivation for an account</h5>} bsStyle={'default'}>
                <div className='alert alert-info margin-bottom-10'>
                    Deactivates all services for an account except default-activated services and MAN ServiceCare S/M
                    (if checkbox is not checked).
                </div>
                <div>
                    <div className={'form-group'}>
                        <Checkbox checked={includeServiceCare}
                                  onChange={() => setIncludeServiceCare(!includeServiceCare)}>
                            Deactivate MAN ServiceCare S/M as well
                        </Checkbox>
                    </div>
                    <div className={'form-group'}>
                        <label>{'Account id*'}</label>
                        <ClearableInput
                            placeholder='Insert account id'
                            value={accountId}
                            onChange={(accountId: string) => setAccountId(accountId.trim())}
                        />
                    </div>
                </div>
                <ConditionallyEnabledButton
                    disabledText={missingData ? 'Please enter account id' : undefined}
                    onClick={async () => {
                        setShowDialog(true);
                        setSummary(undefined);
                        try {
                            setSummary(filterDefaultActivatedProduct(await fetchActivationsSummary(accountId), includeServiceCare));
                        } catch (e) {
                            setShowDialog(false);
                            Notification.error(
                                `Could not fetch activations for account ${accountId}. Does the account exist?`
                            );
                        }
                    }}
                >
                    Cancel ALL activations
                </ConditionallyEnabledButton>
                <ConfirmationDialog
                    show={showDialog}
                    content={
                        <div>
                            Do you really want to cancel all service activations including the ones below?
                            {summary ? <AccountSummary summary={summary}/> : <Spinner text={'Loading summary'}/>}
                            <div className='text-color-warning'>
                                <span className='rioglyph rioglyph-warning-sign margin-right-5'/>
                                Please be aware that this will also cancel active contracts
                            </div>
                        </div>
                    }
                    cancelButtonText={'No'}
                    confirmButtonText={isDeactivating ? 'Deactivating...' : 'Yes'}
                    onClickCancel={() => setShowDialog(false)}
                    disableConfirm={!summary || isDeactivating}
                    onClickConfirm={async () => {
                        if (summary) {
                            setIsDeactivating(true);
                            await cancelAllSubscriptions(accountId, includeServiceCare);
                            setShowDialog(false);
                            setIsDeactivating(false);
                        } else {
                            Notification.error('No activations found');
                        }
                    }}
                    title={'Forced cancellation'}
                    useOverflow={false}
                />
            </ExpanderPanel>
        </div>
    );
};

function filterDefaultActivatedProduct(summary: Array<ActivationSummary>, includeServiceCare: boolean): Array<ActivationSummary> {
    const products = getProductsToDeactivate(includeServiceCare);
    return summary.filter((item) => products.some((product) => product.id.toLowerCase() === item.productId.toLowerCase()));
}

function getProductsToDeactivate(includeServiceCare: boolean) {
    return nonDefaultActivatedProducts.filter(
        (product) => includeServiceCare || product.id.toUpperCase() !== 'MAN-SKU00000049'
    );
}

async function cancelAllSubscriptions(accountId: string, includeServiceCare: boolean) {
    try {
        const products = getProductsToDeactivate(includeServiceCare);
        for (const productId of products.map((product) => product.id)) {
            await deactivateResourcesForProduct(accountId, productId, undefined);
        }
        Notification.success(`All activations for account ${accountId} were cancelled`);
    } catch (e) {
        Notification.error(`Could not cancel activations for account ${accountId}: ${e.message}`);
    }
}
