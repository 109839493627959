import qs, { IStringifyOptions } from 'qs';

const STRINGIFY_OPTIONS: IStringifyOptions = {
    arrayFormat: 'comma', // required to stringify arrays into comma separated strings
    indices: false, // don't use array indices
    encode: false, // don't encode the entire string as it will be done individually for certain params
    skipNulls: true, // required to remove empty params
};

export function getParameters(params: { [key: string]: string | number | undefined }): string {
    const queryParameters = qs.stringify(params, STRINGIFY_OPTIONS);
    return queryParameters ? `?${queryParameters}` : '';
}
