import { connect } from 'react-redux';
import { NavLink, Route, Routes } from 'react-router-dom';
import ApplicationHeader from '@rio-cloud/rio-uikit/lib/es/ApplicationHeader';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import { DiscountPage } from './discount/DiscountPage';
import { ActivationPage } from './activation/ActivationPage';
import DefaultRedirect from './routes/DefaultRedirect';
import { getIdToken } from '../../configuration/tokenHandling/tokenSlice';
import { RootState } from '../../configuration/setup/store';
import { Profile } from 'oidc-client';
import { ACTIVATION_ROUTE, DISCOUNT_ROUTE } from './routes/routes';

export interface Props {
    idToken: Profile;
}

const iamHelpGangTab = {
    key: 'accounts',
    route: <a href={'https://helpgang.iam.rio.cloud/#/accounts'}>{'Accounts'}</a>,
};

const partnerIntegrationsHelpGangTab = {
    key: 'partner-integrations',
    route: <a href={'https://helpgang.iam.rio.cloud/#/partner-integrations'}>{'Partner Integrations'}</a>,
};

const clientMetricsHelpGangTab = {
    key: 'client-metrics',
    route: <a href={'https://helpgang.iam.rio.cloud/#/client-metrics'}>{'Client Metrics'}</a>,
};

const inboundConnectionsHelpGangTab = {
    key: 'inbound-connections',
    route: <a href={'https://helpgang.iam.rio.cloud/#/inbound-connections'}>{'Inbound Connections'}</a>,
};

const assetsHelpGangTab = {
    key: 'assets',
    route: <a href={'https://helpgang.assets.rio.cloud'}>{'Assets'}</a>,
};

const deviceStateAssetsHelpGangTab = {
    key: 'device-state',
    route: <a href={'https://helpgang.assets.rio.cloud/#/device-state'}>{'Device State'}</a>,
};

const App = (props: Props) => {
    const { idToken } = props;

    const tabKeys = ['activation', 'discount'];

    if (!idToken?.sub?.startsWith('azure-my-rio-de')) {
        window.location.href = 'https://home.rio.cloud';
    }

    return (
        <ApplicationLayout>
            <ApplicationLayout.Header>
                <ApplicationHeader
                    label={'Marketplace Admin'}
                    navItems={[
                        iamHelpGangTab,
                        partnerIntegrationsHelpGangTab,
                        clientMetricsHelpGangTab,
                        assetsHelpGangTab,
                        deviceStateAssetsHelpGangTab,
                        inboundConnectionsHelpGangTab,
                        ...tabKeys.map(createNavItem),
                    ]}
                    actionBarItems={[]}
                />
            </ApplicationLayout.Header>
            <ApplicationLayout.Body>
                <NotificationsContainer />
                <Routes>
                    <Route path={ACTIVATION_ROUTE} element={<ActivationPage />} />,
                    <Route path={DISCOUNT_ROUTE} element={<DiscountPage />} />
                    <Route path='*' element={<DefaultRedirect />} />
                </Routes>
            </ApplicationLayout.Body>
        </ApplicationLayout>
    );
};

const createNavItem = (key: string) => ({
    key,
    route: <NavLink to={`/${key}`}>{key.charAt(0).toUpperCase() + key.slice(1)}</NavLink>,
});

const mapStateToProps = (state: RootState): Props => ({
    idToken: getIdToken(state)!!,
});

export const AppContainer = connect(mapStateToProps)(App);
