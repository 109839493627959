/* eslint-disable camelcase*/
import { getMarketplaceBackend } from '../../config/appConfig';
import { postRequest } from '../requests';
import { rejectIfNotOk } from '../api';
import { ApiSubscriptionCancelPayload } from './activation.types';

function createBody(resource_ids: string[]): ApiSubscriptionCancelPayload {
    return { resource_ids: resource_ids };
}

export function deactivateResourcesForProduct(
    accountId: string,
    productId: string,
    resourceIds: string[] | undefined
): Promise<void> {
    const body = resourceIds ? createBody(resourceIds) : undefined;
    return fetch(
        `${getMarketplaceBackend()}/admin/${accountId}/activations/${productId}/deactivate`,
        postRequest(body)
    ).then(rejectIfNotOk);
}
