import ClearableInput from '@rio-cloud/rio-uikit/lib/es/ClearableInput';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';
import { useState } from 'react';
import { ConditionallyEnabledButton } from './ConditionallyEnabledButton';
import { validateStates } from './stateValidator';
import { InputData, ProductState, ResourceActivation } from './types';
import { DisplayValidationError } from './DisplayValidationErrors';
import { deactivateResourcesForProduct } from '../api/activation/deactivationCall';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { splitResourceIds } from './resourceIdSplitter';
import { ProductSelect } from './ProductSelect';

export const ForcedCancellationSingleProduct = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [inputData, setInputData] = useState<InputData>({
        accountId: '',
        productId: '',
        resourceId: '',
    });
    const [validationResult, setValidationResult] = useState<Array<ResourceActivation> | undefined>(undefined);
    const [isValidating, setIsValidating] = useState<boolean>(false);
    const [isDeactivating, setIsDeactivating] = useState<boolean>(false);
    const missingData =
        inputData.productId === '' || inputData.accountId === '' || splitResourceIds(inputData.resourceId).length === 0;
    const showValidationWarning = validationResult?.some((validation) => validation.error);

    return (
        <div className={'margin-20'}>
            <ExpanderPanel title={<h5>Forced deactivation for a single service</h5>} bsStyle={'default'}>
                <div>
                    <div className={'form-group'}>
                        <label>{'Account id*'}</label>
                        <ClearableInput
                            placeholder='Insert account id'
                            value={inputData.accountId}
                            onChange={(accountId: string) =>
                                setInputData({ ...inputData, accountId: accountId.trim() })
                            }
                        />
                    </div>
                    <ProductSelect
                        onChange={(item) => setInputData({ ...inputData, productId: item?.id ?? '' })}
                        selectedId={inputData.productId}
                        purpose='force-cancellation'
                    />
                    <div className={'form-group'}>
                        <label>{'Resource ids (comma-separated)*'}</label>
                        <ClearableInput
                            placeholder='Insert resource ids'
                            value={inputData.resourceId}
                            onChange={(resourceId: string) =>
                                setInputData({ ...inputData, resourceId: resourceId.trim() })
                            }
                        />
                    </div>
                </div>
                <ConditionallyEnabledButton
                    disabledText={missingData ? 'Please enter account id, service sku and resource ids' : undefined}
                    onClick={async () => {
                        setShowDialog(true);
                        setIsValidating(true);
                        const activationItems = [
                            {
                                accountId: inputData.accountId,
                                productId: inputData.productId,
                                resourceIds: splitResourceIds(inputData.resourceId),
                            },
                        ];
                        await validateStates(
                            activationItems,
                            setValidationResult,
                            [ProductState.PENDING_ACTIVATION, ProductState.ACTIVE, ProductState.PENDING_CANCELLATION],
                            setIsValidating
                        );
                    }}
                >
                    Deactivate service
                </ConditionallyEnabledButton>
                <ConfirmationDialog
                    show={showDialog}
                    content={
                        <div>
                            Do you really want to cancel the following activations?
                            {showValidationWarning && (
                                <div className='panel panel-warning margin-top-10'>
                                    <div className='panel-heading'>
                                        <div className='panel-title'>Deactivation may not have an effect</div>
                                    </div>
                                    <div className='panel-body'>
                                        Either an activation is not present or it is already inactive. See below for
                                        more details. Nevertheless, a contract may still be active and is cancelled when
                                        proceeding.
                                    </div>
                                </div>
                            )}
                            <div className={'margin-top-20 max-height-75vh overflow-auto'}>
                                <DisplayValidationError
                                    validationResult={validationResult}
                                    isLoading={isValidating}
                                    expanderText={`Validation result`}
                                    loadingText={'Validating state in the system...'}
                                    startText={'No Validation happened yet'}
                                    noErrorText={'All resources are in the expected state'}
                                />
                            </div>
                        </div>
                    }
                    confirmButtonText={isDeactivating ? 'Deactivating...' : 'Yes'}
                    cancelButtonText={'No'}
                    disableConfirm={isValidating || isDeactivating}
                    onClickCancel={() => setShowDialog(false)}
                    onClickConfirm={async () => {
                        setIsDeactivating(true);
                        try {
                            await deactivateResourcesForProduct(
                                inputData.accountId,
                                inputData.productId,
                                splitResourceIds(inputData.resourceId)
                            );
                            setShowDialog(false);
                            Notification.success(`Successfully deactivated service ${inputData.productId}`);
                        } catch (e) {
                            Notification.error(`Could not deactivate service: ${e.message}`);
                        }
                        setIsDeactivating(false);
                    }}
                    title={'Forced deactivation'}
                    useOverflow={false}
                />
            </ExpanderPanel>
        </div>
    );
};
