import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import { BookableInfo } from '../types';

export function BookableInfoDialog(props: {
    bookableResourceResult: Array<BookableInfo> | undefined;
    onClickClose: () => void;
}) {
    const footer = (
        <button className='btn btn-primary' onClick={props.onClickClose} type='button'>
            Close
        </button>
    );

    return (
        <div className={'margin-top-20 max-height-75vh overflow-auto'}>
            <Dialog
                show={true}
                title={'Bookable Resource'}
                body={
                    props.bookableResourceResult && (
                        <ExpanderPanel title={<b>Bookable Resources</b>} bsStyle='default' open={true}>
                            <table className={'table'}>
                                <thead>
                                    <tr>
                                        {['ResourceId', 'Name', 'Type', 'State'].map((it) => (
                                            <th key={it}>{it}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.bookableResourceResult
                                        ?.filter((item) => item.bookable)
                                        .map((bookableInfo, index) => (
                                            <tr key={index}>
                                                <td>{bookableInfo.resourceId}</td>
                                                <td>{bookableInfo.resourceName}</td>
                                                <td>{bookableInfo.resourceType}</td>
                                                <td>{bookableInfo.activationState}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </ExpanderPanel>
                    )
                }
                footer={footer}
                onHide={props.onClickClose}
                showCloseButton={true}
                onClose={props.onClickClose}
            />
        </div>
    );
}
