import Select, { SelectOption } from '@rio-cloud/rio-uikit/lib/es/Select';

// See products here https://confluence.collaboration-man.com/display/PLTFRMDOC/Products+SKU
export const products: Array<{ id: string; name: string; defaultActivated?: boolean; resetEnabled?: boolean }> = [
    { id: 'LATAM-SKU00000001', name: 'LATAM Essentials', defaultActivated: true },
    { id: 'MAN-SKU00000004', name: 'Compliant' },
    { id: 'MAN-SKU00000005', name: 'Perform' },
    { id: 'LATAM-SKU00000006', name: 'LATAM Geo', defaultActivated: true },
    { id: 'LATAM-SKU00000007', name: 'LATAM Advance', defaultActivated: true },
    { id: 'MAN-SKU00000026', name: 'MAN Now', defaultActivated: true },
    { id: 'RIO-SKU00000027', name: 'RIO Timed' },
    { id: 'WABCO-SKU00000030', name: 'RIO 4TX-TRAILERPULSE' },
    { id: 'RIO-SKU00000045', name: 'Dispatch' },
    { id: 'RIO-SKU00000047', name: 'Geo' },
    { id: 'MAN-SKU00000048', name: 'One Minute Locator' },
    { id: 'MAN-SKU00000049', name: 'MAN ServiceCare S/M', resetEnabled: false },
    { id: 'MAN-SKU00000050', name: 'MAN eManager' },
    { id: 'RIO-SKU00000051', name: 'Cypress Connect' },
    { id: 'MAN-SKU00000052', name: 'MAN Now MapUpdate' },
    { id: 'KOR-SKU00000053', name: 'MAN ServiceCare S (South Korea)' },
    { id: 'KOR-SKU00000054', name: 'Perform (South Korea)' },
    { id: 'RIO-SKU00000055', name: 'Geo Data Flow' },
    { id: 'RIO-SKU00000056', name: 'Timed Data Flow' },
    { id: 'RIO-SKU00000058', name: 'Cypress Connect MultiLevel' },
    { id: 'RIO-SKU00000059', name: 'Cypress Connect MultiLevel Confirmation' },
    { id: 'MAN-SKU00000060', name: 'MAN Now OnlineTraffic' },
    { id: 'MAN-SKU00000061', name: 'Minimaldatenset' },
    { id: 'RIO-SKU00000065', name: 'Fleet' },
    { id: 'MAN-SKU00000066', name: 'MAN SimplePay' },
    { id: 'RIO-SKU00000070', name: 'Order' },
    { id: 'RIO-SKU00000071', name: 'User Test Product' },
    { id: 'MAN-SKU00000072', name: 'MAN Now EfficientRoute' },
    { id: 'MAN-SKU00000073', name: 'MAN DataPackage' },
    { id: 'MAN-SKU00000076', name: 'MAN Now OnlineNews' },
    { id: 'MAN-SKU00000085', name: 'MAN SmartRoute' },
    { id: 'LATAM-SKU00000075', name: 'LATAM Test Product' },
    { id: 'LATAM-SKU00000078', name: 'Brazil RIO Premium' },
    { id: 'LATAM-SKU00000079', name: 'Brazil RIO E-Fleet' },
    { id: 'LATAM-SKU00000080', name: 'Brazil RIO Basics' },
    { id: 'MAN-SKU00000081', name: 'MAN SimplePay (Test)' },
    { id: 'LATAM-SKU00000088', name: 'RIO Basics + VolksTotal' },
    { id: 'LATAM-SKU00000089', name: 'RIO Premium + VolksTotal' },
    { id: 'LATAM-SKU00000090', name: 'RIO E-Fleet + VolksTotal' },
    { id: 'LATAM-SKU00000095', name: 'Volks|Blocker' },
];

export const nonDefaultActivatedProducts = products.filter((product) => !product.defaultActivated);

const productOptions = products
    .map((product) => ({ ...product, label: `${product.name} (${product.id})` }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const ProductSelect = (props: {
    onChange: (item: SelectOption | undefined) => void;
    selectedId?: string;
    showDefaultActivated?: boolean;
    purpose: 'view-activation' | 'reset' | 'force-cancellation';
}) => {
    let options = productOptions
        .filter((option) => props.showDefaultActivated || !option.defaultActivated)
        .filter((option) => props.purpose !== 'reset' || option.resetEnabled !== false)
        .map((option) => ({ ...option, selected: option.id === props.selectedId }));
    return (
        <div className={'form-group'}>
            <label>{'Service sku*'}</label>
            <Select
                placeholder={'Please select...'}
                options={options}
                tabIndex={1}
                onChange={props.onChange}
                className={'productSelect'}
                useFilter
            />
        </div>
    );
};
