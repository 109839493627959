import { useState } from 'react';
import { FALLBACK_BROWSER_INPUT, readAndValidationInputFromBrowser } from './inputReader';
import { DisplayInput } from './DisplayInput';
import { ValidationSection } from './ValidationSection';
import { BrowserInput } from './types';
import { ActivationSection } from './ActivationSection';
import { DeactivationSection } from './DeactivationSection';
import { InputExample } from './InputExample';
import { ForcedCancellationSingleProduct } from './ForcedCancellationSingleProduct';
import { ForcedCancellationFullAccount } from './ForcedCancellationFullAccount';
import { ActivationStatusResources } from './ActivationStatusResources';
import { ActivationStatusAccount } from './ActivationStatusAccount';
import { ResetActivationSingleProduct } from './reset/ResetActivationSingleProduct';
import { BookableResource } from './bookable/BookableResource';
import { ForcedCancellationProductMultipleAccounts } from './ForcedCancellationProductMultipleAccounts';
import { FreeTrial } from './freetrial/FreeTrial';

export const ActivationPage = () => {
    const [input, setInput] = useState<BrowserInput>(FALLBACK_BROWSER_INPUT);

    return (
        <div className='module-content'>
            <div className={'container-fluid fluid-default'}>
                <div className='list-group'>
                    <div className='list-group-item margin-bottom-10 margin-top-10'>
                        <ActivationStatusAccount />
                    </div>
                    <div className='list-group-item margin-bottom-10 margin-top-10'>
                        <ActivationStatusResources />
                    </div>
                    <div className='list-group-item margin-bottom-10 margin-top-10'>
                        <ForcedCancellationSingleProduct />
                    </div>
                    <div className='list-group-item margin-bottom-10 margin-top-10'>
                        <ResetActivationSingleProduct />
                    </div>
                    <div className='list-group-item margin-bottom-10 margin-top-10'>
                        <ForcedCancellationFullAccount />
                    </div>
                    <div className='list-group-item margin-bottom-10 margin-top-10'>
                        <BookableResource />
                    </div>
                    <div className='list-group-item margin-bottom-10 margin-top-10'>
                        <FreeTrial />
                    </div>
                    {document.location.search.includes('productCancellation') && (
                        <div className='list-group-item margin-bottom-10 margin-top-10'>
                            <ForcedCancellationProductMultipleAccounts />
                        </div>
                    )}
                    <div className='list-group-item'>
                        <InputExample />
                        <button
                            className={'btn btn-primary margin-20'}
                            onClick={() => setInput(readAndValidationInputFromBrowser())}
                        >
                            Import data from window.input from browser
                        </button>
                        <DisplayInput input={input} />
                    </div>
                    {!input.error && (
                        <div>
                            <div className='list-group-item margin-bottom-10 margin-top-10'>
                                <ValidationSection activationItems={input.items} />
                            </div>
                            <div className='list-group-item margin-bottom-10 margin-top-10'>
                                <ActivationSection input={input} />
                            </div>
                            <div className='list-group-item margin-bottom-10 margin-top-10'>
                                <DeactivationSection input={input} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
