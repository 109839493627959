import ClearableInput from '@rio-cloud/rio-uikit/lib/es/ClearableInput';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import { useState } from 'react';
import { InputData, ResourceActivation } from './types';
import { ConditionallyEnabledButton } from './ConditionallyEnabledButton';
import { validateStates } from './stateValidator';
import { DisplayValidationError } from './DisplayValidationErrors';
import { splitResourceIds } from './resourceIdSplitter';
import { ProductSelect } from './ProductSelect';

function convertToActivationsItems({ accountId, productId, resourceId }: InputData) {
    return [{ productId, accountId, resourceIds: splitResourceIds(resourceId) }];
}

export const ActivationStatusResources = () => {
    const [inputData, setInputData] = useState<InputData>({
        accountId: '',
        resourceId: '',
        productId: '',
    });
    const [validationResult, setValidationResult] = useState<Array<ResourceActivation> | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const missingData = inputData.productId === '' || inputData.accountId === '';

    return (
        <div className={'margin-20'}>
            <ExpanderPanel title={<h5>Activation status for resources</h5>} bsStyle={'default'}>
                <div>
                    <div className={'form-group'}>
                        <label>{'Account id*'}</label>
                        <ClearableInput
                            placeholder='Insert account id'
                            value={inputData.accountId}
                            onChange={(accountId: string) =>
                                setInputData({ ...inputData, accountId: accountId.trim() })
                            }
                        />
                    </div>
                    <ProductSelect
                        onChange={(item) => setInputData({ ...inputData, productId: item?.id ?? '' })}
                        selectedId={inputData.productId}
                        showDefaultActivated={true}
                        purpose='view-activation'
                    />
                    <div className={'form-group'}>
                        <label>{'Resource ids (comma-separated)'}</label>
                        <ClearableInput
                            placeholder='Insert resource ids'
                            value={inputData.resourceId}
                            onChange={(resourceId: string) =>
                                setInputData({ ...inputData, resourceId: resourceId.trim() })
                            }
                        />
                    </div>
                </div>
                <div className={'height-40'}>
                    <ConditionallyEnabledButton
                        onClick={async () => {
                            await validateStates(
                                convertToActivationsItems(inputData),
                                setValidationResult,
                                [],
                                setIsLoading
                            );
                        }}
                        disabledText={missingData ? 'Please enter at least account id and product id' : undefined}
                    >
                        Find activations
                    </ConditionallyEnabledButton>
                </div>
                <div className={'margin-top-20'}>
                    <DisplayValidationError
                        validationResult={validationResult?.map((validation) => ({
                            ...validation,
                            error: validation.error?.split(' is not within the desired states').join(''),
                        }))}
                        isLoading={isLoading}
                        expanderText={'Result for selected account and product'}
                        loadingText={'Retrieving state from the system...'}
                        startText={'No request happened yet'}
                        noErrorText={'No activations found for this product'}
                        stateInsteadOfError={true}
                    />
                </div>
            </ExpanderPanel>
        </div>
    );
};
