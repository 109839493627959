import { configureStore } from '@reduxjs/toolkit';
import configReducer from './configSlice';
import loginReducer from '../login/loginSlice';
import tokenReducer from '../tokenHandling/tokenSlice';
import apiReducer from '../../features/app/api/redux/api.redux';

export const store = configureStore({
    reducer: {
        config: configReducer,
        login: loginReducer,
        tokenHandling: tokenReducer,
        api: apiReducer,
    },
});

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;
