import ClearableInput from '@rio-cloud/rio-uikit/lib/es/ClearableInput';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import { useState } from 'react';
import { ConditionallyEnabledButton } from '../ConditionallyEnabledButton';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { setFreeTrialEligible } from '../../api/freetrial/freeTrialCall';

export function FreeTrial() {
    const [accountId, setAccountId] = useState<string>('');
    const [inProgress, setInProgress] = useState<boolean>(false);

    return (
        <div className={'margin-20'}>
            <ExpanderPanel title={<h5>Free Trial</h5>} bsStyle={'default'}>
                <div>
                    <div className={'form-group'}>
                        <label>{'Account id*'}</label>
                        <ClearableInput
                            placeholder='Insert account id'
                            value={accountId}
                            onChange={(input: string) => setAccountId(input.trim())}
                        />
                    </div>
                    <ConditionallyEnabledButton
                        disabledText={getDisabledText(!accountId, inProgress)}
                        onClick={async () => {
                            setInProgress(true);
                            try {
                                await setFreeTrialEligible(accountId);
                                Notification.success('Free trial eligibility set successfully');
                            } catch (e) {
                                Notification.error(`Could not execute free-trials service: ${e.message}`);
                            } finally {
                                setInProgress(false);
                            }
                        }}
                    >
                        Make Eligible for Free Trial
                    </ConditionallyEnabledButton>
                </div>
            </ExpanderPanel>
        </div>
    );
}

function getDisabledText(missingData: boolean, inProgress: boolean) {
    if (missingData) {
        return 'Please enter account id';
    }
    if (inProgress) {
        return 'In progress ...';
    }
    return undefined;
}
