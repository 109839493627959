import { ActivationInfo, ResourceActivation, OverviewResource, ProductState } from './types';
import { fetchActivationsForProduct } from '../api/activation/activationStateCall';

function isActivationValid(
    activation: OverviewResource,
    desiredProductStates: Array<ProductState>,
    level: string | undefined
) {
    if (desiredProductStates.includes(activation.productState)) {
        if (level && desiredProductStates.length === 1 && desiredProductStates.includes(ProductState.ACTIVE)) {
            const isActivatedForExpectedLevel = activation.targetLevel === level;
            return isActivatedForExpectedLevel
                ? undefined
                : `State is ${activation.productState}, but targetLevel is ${activation.targetLevel}`;
        }
        return undefined;
    }
    return `State ${activation.productState} is not within the desired states${
        level ? ` (target: ${activation.targetLevel ?? '---'}, origin: ${activation.originLevel ?? '---'})` : ''
    }`;
}

function validateState(
    accountId: string,
    productId: string,
    activation: OverviewResource,
    states: Array<ProductState>,
    level: string | undefined
): ResourceActivation {
    return {
        accountId,
        productId,
        level,
        resourceId: activation.id,
        productState: activation.productState,
        error: isActivationValid(activation, states, level),
    };
}

export function noAssetFound(accountId: string, productId: string, resourceId: string): ResourceActivation {
    return {
        accountId,
        productId,
        resourceId,
        error: 'Asset not found',
    };
}

export async function validateStates(
    activationItems: Array<ActivationInfo>,
    setValidationResult: (value: Array<ResourceActivation>) => void,
    desiredProductStates: Array<ProductState>,
    setIsLoading?: (value: boolean) => void
) {
    const validationResults: Array<ResourceActivation> = [];

    setIsLoading && setIsLoading(true);
    for (const item of activationItems) {
        const { accountId, productId, resourceIds } = item;
        await fetchActivationsForProduct(accountId, productId)
            .then((activations) => {
                if (activations.length === 0) {
                    validationResults.push({
                        accountId,
                        productId,
                        error: 'No entries found for this account',
                    });
                } else if (resourceIds.length > 0) {
                    resourceIds.forEach((resourceId) => {
                        const activation = activations.find((it) => it.id === resourceId);
                        if (activation) {
                            validationResults.push(
                                validateState(
                                    accountId,
                                    productId,
                                    activation,
                                    desiredProductStates,
                                    activation.targetLevel
                                )
                            );
                        } else {
                            validationResults.push(noAssetFound(accountId, productId, resourceId));
                        }
                    });
                } else {
                    activations.forEach((activation) =>
                        validationResults.push(
                            validateState(
                                accountId,
                                productId,
                                activation,
                                desiredProductStates,
                                activation.targetLevel
                            )
                        )
                    );
                }
            })
            .catch((error: Error) => validationResults.push({ accountId, productId, error: error.message }));
    }
    setValidationResult(validationResults);
    setIsLoading && setIsLoading(false);
}
